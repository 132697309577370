









































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  useMeta
} from '@nuxtjs/composition-api';
import NostoNotFound from 'atoms/NostoNotFound.vue';

import { useConfig } from '~/composables';
import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import { useTransformedMeta } from '~/integrations/contentful/src/helper/metaHelper';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Page404',
  components: {
    NostoNotFound,
    VaimoBanner: () => import('~/components/atoms/VaimoBanner.vue'),
    VaimoButton: () => import('~/components/atoms/VaimoButton.vue'),
    SectionsList: () => import('~/components/templates/SectionsList.vue')
  },
  scrollToTop: true,
  setup() {
    const { transformMeta } = useTransformedMeta();
    const { getPageBySlug, page } = useContentfulPage('page404');
    const { query, queryResponse } = useContentfulGQL('page404');
    const sectionsItems = computed(() => page.value?.sectionsCollection?.items);
    const headerBannerData = computed(
      () => queryResponse.value?.assetCollection?.items[0] || {}
    );
    const headerBannerDataDesktop = computed(
      () => queryResponse.value?.assetCollection?.items[1] || {}
    );

    const { isMobile } = useScreenSize();
    const metaTags = computed(() => ({
      title: '404 | Diptyque Paris',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: '404 | Diptyque Paris'
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      ]
    }));
    const metadata = computed(() =>
      page.value?.metaData
        ? transformMeta(page.value?.metaData)
        : metaTags.value
    );

    useMeta(metadata.value);
    // TODO: i.sprut investigate why absense of it causing issues with bundle
    useConfig();

    onBeforeMount(async () => {
      return Promise.all([
        query('getAssetByTitle', {
          title: 'Page 404 Banner New'
        }),
        getPageBySlug('page404')
      ]);
    });

    onMounted(async () => {
      window.scrollTo(0, 0);
    });

    return {
      sectionsItems,
      headerBannerData,
      headerBannerDataDesktop,
      isMobile
    };
  },
  head: {}
});
