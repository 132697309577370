export default `
  query getCategoryUrlPath($url: String!, $is_preview: Boolean = false)
  {
    route(url: $url, is_preview: $is_preview) {
      __typename
      relative_url
      redirect_code
      type
      ... on CategoryTree {
        uid
        id
        level
        name
        description
        path
        url_path
        url_key
        url_suffix
        children_count
        listing_layout
        meta_title
        meta_description
        products {
          items {
            sku
            isEngravingEnabled
          }
        }
        children {
          uid
          id
          level
          name
          path
          url_path
          url_key
          children {
            uid
            id
            level
            name
            path
            url_path
            url_key
          }
        }
      }
    }
  }
`;
