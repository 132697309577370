export default `
  query customCategoryList(
    $url: String!
    $is_preview: Boolean = false
  ) {
    route(url: $url, is_preview: $is_preview) {
      __typename
      ... on CategoryInterface {
        uid
        level
        name
        breadcrumbs {
          category_uid
          category_name
          category_level
          category_url_key
          category_url_path
        }
      }
    }
  }
`;
